<ng-container *transloco="let t; read: 'dialog-template'">
  @if ((dialogTitle || confirmDialogTitle) && !disableClose) {
    <div class="header">
      <h2 mat-dialog-title
        [innerHTML]="dialogTitle || confirmDialogTitle">
      </h2>
    </div>
  }

  @if (!formGroup) {
    <ng-container *ngTemplateOutlet="dialogContentAndActions"></ng-container>
  } @else {
    <form [formGroup]="formGroup"
      (ngSubmit)="btnActionClick.emit()"
      autocomplete="off">
      <ng-container *ngTemplateOutlet="dialogContentAndActions"></ng-container>
    </form>
  }

  <ng-template #dialogContentAndActions>
    <mat-dialog-content>
      <ng-content></ng-content>
    </mat-dialog-content>

    @if (showActions) {
      <mat-dialog-actions [align]="alignActions">
        <button mat-stroked-button
          data-cy="btn-dialog-cancel"
          type="button"
          class="mat-default"
          [class.trailing-margin]="!!formGroup"
          (click)="onCancelBtnClick()">
          {{ btnCancelText || t('cancel') }}
        </button>

        @if (!formGroup) {
          <button mat-flat-button
            data-cy="btn-dialog-action"
            type="button"
            [color]="btnActionColor"
            [disabled]="disableBtnAction"
            (click)="btnActionClick.emit()">
            {{ btnActionText }}
          </button>
        }

        @if (!!formGroup) {
          <accredible-button-loading dataCy="btn-dialog-action"
            btnType="submit"
            [isLoading]="isSavingForm"
            [btnText]="btnActionText"
            [disableBtn]="disableBtnAction"
          />
        }
      </mat-dialog-actions>
    }

  </ng-template>

  @if (!showActions || !disableClose) {
    <div class="close-dialog">
      <button mat-icon-button
        data-cy="btn-dialog-close"
        [attr.aria-label]="t('close_dialog')"
        (click)="onCancelBtnClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  }

</ng-container>

