import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { Injectable, Type } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AccredibleConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class AccredibleDialogService {
  private _config: MatDialogConfig = {
    width: '500px',
    panelClass: 'acc-dialog',
    ariaModal: true,
  };

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _language: AccredibleLanguageService,
  ) {}

  open<C>(component: Type<C>, config: MatDialogConfig = {}): MatDialogRef<C> {
    return this._dialog.open(component, {
      ...this._config,
      ...config,
      autoFocus: config.autoFocus ?? 'first-heading',
      ariaLabel: config.ariaLabel ?? this._getAriaLabelFromTitle(config.data?.dialogTitle),
      data: config.data,
      direction: this._language.getDirection(),
    });
  }

  confirm(confirmDialogData: ConfirmDialogData): MatDialogRef<AccredibleConfirmDialogComponent> {
    return this._dialog.open(AccredibleConfirmDialogComponent, {
      ...this._config,
      width: '400px',
      ariaLabel:
        confirmDialogData.ariaLabel ?? this._getAriaLabelFromTitle(confirmDialogData.title),
      data: confirmDialogData,
      direction: this._language.getDirection(),
    });
  }

  closeAll(): void {
    this._dialog.openDialogs.forEach((dialog: MatDialogRef<any>) => {
      dialog.close({});
    });
  }

  private _getAriaLabelFromTitle(title: string): string {
    return title?.replace(/<[^>]*>/g, '');
  }
}
