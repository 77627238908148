export interface SeoParams {
  title?: string;
  description?: string;
  identity?: string;
  spiderable?: SpiderableOptions;
  image?: string;
  url?: string;
  duplicateOf?: string;
  twitterCard?: string;
  twitterSite?: string;
  twitterCreator?: string;
  twitterImage?: string;
}

export interface SeoCourseParams {
  name: string;
  description: string;
  organization: {
    name: string;
    url: string;
  };
}

export enum SpiderableOptions {
  ALL = 'all',
  NOINDEX = 'noindex',
  NOFOLLOW = 'nofollow',
  NOINDEX_NOFOLLOW = 'noindex, nofollow',
}
